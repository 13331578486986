// Used in projects components that were moved from intranet without refactoring.
// Not recommended to use in new code.
// Should be refactored along with the entire projects module.

export default {
  props: {
    dialog: Boolean,
    index: Number,
    item: Object,
  },

  data() {
    return {
      requestPending: false,
    };
  },

  watch: {
    dialog(val) {
      if (!val) {
        for (const key in this.backendErrors) {
          this.backendErrors[key] = [];
        }
      } else {
        this.requestPending = false;
        this.prepareForm();
      }
    },
  },

  created() {
    this.prepareForm();
  },

  methods: {
    getRequiredFieldLabel(label) {
      return `${label}*`;
    },

    // override inside components when additional actions need to be performed
    // when the form is opened
    prepareForm() {},

    save(apiRoute) {
      let idField;
      const requestBody = JSON.parse(JSON.stringify(this.item));
      for (const key in requestBody) {
        if (key.indexOf('_id') > -1) {
          idField = key.slice(0, key.length - 3);
          if (requestBody[idField]) {
            delete requestBody[idField];
          }
        }
      }
      this.requestPending = true;
      if (this.index > -1) {
        this.$http.put(`${apiRoute}${requestBody.id}`, requestBody).then((response) => {
          const item = response.data.data ? response.data.data : response.data;
          if (this.updateAction) {
            this.$store.dispatch(this.updateAction, {
              index: this.index,
              item,
            });
            this.close();
          } else {
            this.$emit('itemUpdated', item);
          }
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      } else {
        this.$http.post(`${apiRoute}`, requestBody).then((response) => {
          const item = response.data.data ? response.data.data : response.data;
          if (this.createAction) {
            this.$store.dispatch(this.createAction, item);
            this.close();
          } else {
            this.$emit('itemCreated', item);
          }
        }).catch((error) => {
          this.handleBackendErrors(error);
        });
      }
    },

    setAutocompleteValue(item, objectField, idField) {
      if (item) {
        this.item[objectField] = item;
        this.item[idField] = item.id;
      } else {
        this.item[objectField] = null;
        this.item[idField] = null;
      }
    },

    setMultiselectValue(items, arrayField) {
      this.item[arrayField] = items;
    },

    clearErrors(field) {
      this.backendErrors[field] = [];
    },

    handleBackendErrors(error) {
      this.requestPending = false;
      if (error.response && error.response.status === 422) {
        for (const key in error.response.data.errors) {
          this.backendErrors[key] = error.response.data.errors[key];
        }
      }
    },

    close(type) {
      this.$emit('dialogClosed', type);
    },
  },
};
