<template>
  <v-card>
    <v-card-title>
      {{ $t('projects.general.user_assignments') }}
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row
            v-for="(assignee, index) of assignees"
            :key="index"
            dense
          >
            <v-col cols="12" sm="6">
              <BaseAutocomplete
                :search-function="userSearchFunction"
                :disabled="assignee.permission === 'creator'"
                :label="$t('user')"
                :item="assignee"
                clearable
                item-text="full_name"
                @input="setAssignee($event, index)"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                append-outer-icon="mdi-close"
                :value="assignee.permission"
                :items="permissionTypes"
                :disabled="assignee.permission === 'creator'"
                :label="$t('role')"
                @click:append-outer="removeAssignee(assignee.user_id, index)"
                @change="setPermission($event, index)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        text
        @click="addAssignee"
      >
        {{ $t('projects.labels.add_user') }}
      </v-btn>
      <v-spacer/>
      <v-btn
        text
        @click.native="close"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click.native="save"
        :disabled="requestPending"
      >
        {{ $t('save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formMixin from '../mixins/form-mixin';
import BaseAutocomplete from '../../base/BaseAutocomplete';
import userService from '../../../api/user-service';

export default {
  name: 'ProjectAssigneeForm',

  components: {
    BaseAutocomplete,
  },

  mixins: [formMixin],

  data() {
    return {
      assignees: [],
      userSearchFunction: userService.search,
      apiRoute: `api/project-assignees/${this.$route.params.id}`,
    };
  },

  computed: {
    permissionTypes() {
      return [
        { text: this.$t('projects.assignee_permissions.creator'), value: 'creator', disabled: true },
        { text: this.$t('projects.assignee_permissions.write'), value: 'write' },
      ];
    },
  },

  methods: {
    prepareForm() {
      this.assignees = JSON.parse(JSON.stringify(this.$store.getters.assignees));
    },

    save() {
      const requestBody = [];
      const count = this.assignees.length;
      for (let i = 0; i < count; i++) {
        if (this.assignees[i].user_id && this.assignees[i].permission) {
          requestBody.push({
            user_id: this.assignees[i].user_id,
            permission: this.assignees[i].permission,
          });
        }
      }
      this.$http.put(`${this.apiRoute}`, { assignees: requestBody }).then((response) => {
        this.$store.dispatch('updateAssignees', response.data.data.assignees);
      }).catch((error) => {
        this.handleBackendErrors(error);
      });
    },

    addAssignee() {
      this.assignees.push({ permission: 'write' });
    },

    setAssignee(assignee, index) {
      if (assignee) {
        this.assignees[index] = { ...assignee, permission: 'write', user_id: assignee.id };
      }
    },

    removeAssignee(id, index) {
      if (id) {
        this.$http.delete(`api/project-assignees/${this.$store.getters.project.id}`, { data: { user_ids: [id] } }).then((response) => {
          this.assignees.splice(index, 1);
        });
      } else {
        this.assignees.splice(index, 1);
      }
    },

    setPermission(value, index) {
      this.assignees[index].permission = value;
    },

    close() {
      this.$store.state.projects.assigneeDialog = false;
    },
  },
};
</script>
