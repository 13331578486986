<template>
  <v-container class="pa-0" fluid>
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :headers="tableMixin_displayedHeaders"
      :items="displayedItems"
      :mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      @click:row="tableMixin_onRowClick"
    >
      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          v-if="item.permission !== 'creator' && $store.getters.projectPermission === 'creator'"
          :actions="actions"
          :item="item"
        />
      </template>
    </v-data-table>

    <!-- Project assignees modal -->
    <v-dialog
      v-model="$store.state.projects.assigneeDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="slide-y-reverse-transition"
      scrollable
      persistent
    >
      <app-assignees-form
        :dialog="$store.state.projects.assigneeDialog"
      />
    </v-dialog>

    <app-primary-action-button
      v-if="showPrimaryActionButton && $store.getters.projectPermission === 'creator'"
      icon="mdi-pencil"
      @click="$store.dispatch('newAssignee')"
    />
  </v-container>
</template>

<script>
import AssigneesForm from './ProjectAssigneeForm.vue';
import loadingMixin from '../mixins/loading-mixin';
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../../../util/event-bus';
import tableMixin from '../../../mixins/table-mixin';
import PrimaryActionButton from '../PrimaryActionButton';
import BaseActionMenu from '../../base/BaseActionMenu';

export default {
  name: 'ProjectAssignees',

  components: {
    BaseActionMenu,
    appPrimaryActionButton: PrimaryActionButton,
    appAssigneesForm: AssigneesForm,
  },

  mixins: [loadingMixin, tableMixin],

  data() {
    return {
      actions: [
        {
          callback: p => this.deleteAssignee(p.user_id),
          label: this.$t('remove'),
          icon: 'mdi-close',
        },
      ],
      headers: [
        { value: 'assigneeName', text: this.$t('user') },
        { value: 'permissionLabel', text: this.$t('role'), hidden: 'xsOnly' },
        { value: 'actions' },
      ],
    };
  },

  computed: {
    displayedItems() {
      return this.$store.getters.assignees.map(r => ({
        ...r,
        assigneeName: r.personal_data?.full_name,
        permissionLabel: this.$t(`projects.assignee_permissions.${r.permission}`),
      }));
    },

    permissionTypes() {
      return [
        { text: this.$t('employee'), value: 'write' },
      ];
    },
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    });
  },

  methods: {
    deleteAssignee(userId) {
      eventBus.$emit(OPEN_CONFIRM_DIALOG, this.$t('projects.confirmations.remove_assignee'));
      this.$eventBus.$on(CONFIRM_DIALOG_CLOSED, (confirmed) => {
        if (confirmed) {
          this.$http.delete(`api/project-assignees/${this.$store.getters.project.id}`, { data: { user_ids: [userId] } }).then((response) => {
            this.$store.dispatch('updateAssignees', response.data.data.assignees);
          });
        }
        this.$eventBus.$off(CONFIRM_DIALOG_CLOSED);
      });
    },
  },
};
</script>
