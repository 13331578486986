// Used in projects components that were moved from intranet without refactoring.
// Not recommended to use in new code.
// Should be refactored along with the entire projects module.

export default {
  data() {
    return {
      loading: true,
      showPrimaryActionButton: false,
    };
  },

  watch: {
    loading(val) {
      if (!val) {
        this.$store.dispatch('hideTopProgress');
        setTimeout(() => {
          this.showPrimaryActionButton = true;
        }, 500);
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.showPrimaryActionButton = false;
    setTimeout(() => {
      next();
    }, 100);
  },
};
